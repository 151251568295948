<template>
  <b-form-group
    label="Bölüm Yöneticisi"
    label-for="id_com_user_manager"
  >
    <validation-provider
      #default="{ errors }"
      name="Bölüm Yöneticisi"
      rules="required"
    >
      <v-select
        id="id_com_user_manager"
        v-model="user.id_com_user_manager"
        label="title"
        :options="userManagers"
        :reduce="userManager => userManager.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Manager',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
    userManagers() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    localize(this.locale)
    this.getUserManagers()
  },
  methods: {
    getUserManagers() {
      this.$store.dispatch('users/usersList', {
        select: ['com_user.id AS id', 'com_user.name AS title'],
        where: {
          'com_user.status': 1,
          'com_user.id_com_user_type <': 5,
        },
      })
    },
  },
}
</script>

<style scoped></style>
