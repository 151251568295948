<template>
  <b-form-group
    label="Webform Durumu"
    label-for="id_com_user_type"
  >
    <validation-provider
      #default="{ errors }"
      name="Webform Durumu"
      rules="required"
    >
      <v-select
        id="id_com_user_type"
        v-model="user.webform"
        label="title"
        :options="webforms"
        :reduce="webform => webform.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Webform',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
      webforms: [
        { id: '1', title: 'Açık' },
        { id: '0', title: 'Kapalı' },
      ],
    }
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped></style>
