<template>
  <div>
    <b-card>
      <template #header>
        <h4>Özel Yetkiler</h4>
        <b-button
          variant="warning"
          class="mb-1"
          @click="addRow"
        >
          <feather-icon icon="PlusIcon" />
          Ekle
        </b-button>
      </template>
      <b-row>
        <b-col
          v-if="dataList.length < 1"
          cols="12"
          md="12"
        >
          <b-alert
            show
            variant="warning"
            class="m-2"
          >
            <div class="alert-body text-center">
              Kullanıcının özel yetkisi bulunmuyor.
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <b-button
                variant="warning"
                size="sm"
                class="mb-1"
                @click="addRow"
              >
                <feather-icon icon="PlusIcon" />
                Ekle
              </b-button>
            </div>
          </b-alert>
        </b-col>
        <b-col v-else>
          <div
            v-for="(row,index) in dataList"
            :key="index"
          >
            <b-row
              class="d-flex justify-content-center align-items-center"
            >
              <b-col>
                <abilities-select-card :a-key="index" />
              </b-col>
              <b-col md="auto">
                <b-button
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  @click="deleteAbility(row,index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
                <b-button
                  variant="flat-success"
                  class="btn-icon rounded-circle"
                  @click="saveAbilitiy(index)"
                >
                  <feather-icon icon="SaveIcon" />
                </b-button>
              </b-col>
            </b-row>
            <hr>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BAlert, BButton, BCard,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AbilitiesSelectCard from '@/views/Core/Users/forms/UserAbilities/AbilitiesSelectCard.vue'

export default {
  name: 'UserAbilitiesCard',
  components: {
    AbilitiesSelectCard,
    BAlert,
    BRow,
    BCol,
    BButton,
    BCard,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
    dataList() {
      return this.$store.getters['userAbilities/getDataList']
    },
    saveDataStatus() {
      return this.$store.getters['userAbilities/saveDataStatus']
    },
  },
  watch: {
    saveDataStatus(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.refreshData()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getAbilities()
  },
  methods: {
    getAbilities() {
      this.$store.dispatch('abilities/abilitiesDataList', {
        select: [
          'com_abilities.id AS id',
          'com_abilities.main_groups AS main_groups',
          'com_abilities.groups AS groups',
          'CONCAT(com_abilities.main_groups," ",com_abilities.groups," - ",com_abilities.title) AS title',
        ],
      })
    },
    addRow() {
      this.dataList.unshift({
        id: null,
        id_com_abilities: null,
        id_com_user: this.user.id,
      })
    },
    saveAbilitiy(index) {
      this.$store.dispatch('userAbilities/saveData', this.dataList[index])
        .then(response => {
          if (response.status) {
            this.getAbilities()
          }
        })
    },
    deleteAbility(row, index) {
      if (row.id !== null) {
        this.$swal({
          title: this.$store.state.app.removeTitle,
          text: this.$store.state.app.removeMessage,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$store.state.app.removeConfirm,
          cancelButtonText: this.$store.state.app.removeCancel,
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('userAbilities/removeData', { id: row.id, id_com_user: row.id_com_user })
              .then(res => {
                if (res) {
                  this.$swal({
                    icon: 'success',
                    title: this.$store.state.app.removeResultTitle,
                    text: this.$store.state.app.removeResultMessage,
                    confirmButtonText: this.$store.state.app.removeResultClose,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  this.refreshData()
                }
              })
          }
        })
      } else {
        this.dataList.splice(index, 1)
      }
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
