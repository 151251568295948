<template>
  <div>
    <b-form-group
      label="İşe Başlama Tarihi"
      label-for="startdate"
    >
      <b-form-datepicker
        id="startdate"
        v-model="user.startdate"
        v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'

export default {
  name: 'Startdate',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
  },
}
</script>
<style scoped></style>
