<template>
  <b-form-group
    label="Yetki"
    label-for="id_com_abilities"
  >
    <validation-provider
      #default="{ errors }"
      name="Yetki"
      rules="required"
    >
      <v-select
        v-model="dataItem[aKey].id_com_abilities"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Giriniz"
        :close-on-select="false"
        multiple
      >
        <template v-slot:option="option">
          {{ option.title }}
          <div>
            <small class="text-primary">{{ option.main_groups }}</small>
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'AbilitiesSelectCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    aKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['abilities/getAbilities']
    },
    dataItem() {
      return this.$store.getters['userAbilities/getDataList']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
