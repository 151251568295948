<template>
  <div>
    <b-form-group
      label="Dahili"
      label-for="internal"
    >
      <cleave
        id="internal"
        v-model="user.internal"
        class="form-control"
        :raw="false"
        :options="options.internal"
        placeholder="Dahili"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Phone',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        internal: {
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
  },
}
</script>
<style scoped></style>
