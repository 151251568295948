<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <template #header>
        <h4>Genel Bilgiler</h4>
        <div>
          <b-form-checkbox
            v-model="user.detailForm"
            name="check-button"
            switch
            inline
          >
            Detaylı
          </b-form-checkbox>
        </div>
      </template>
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <name-surname />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <email />
        </b-col>
      </b-row>
      <b-row v-if="user.detailForm">
        <b-col
          sm="12"
          md="6"
        >
          <birthday />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <start-date />
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <tckn />
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <phone />
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <internal />
        </b-col>
        <b-col
          sm="12"
          md="12"
        >
          <password />
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Marka & Departman Bilgileri">
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <brand />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <department />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <manager />
        </b-col>
        <b-col
          sm="12"
          md="3"
        >
          <user-section />
        </b-col>
        <b-col
          sm="12"
          md="3"
        >
          <location />
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Kullanıcı Tipi & Diğer Bilgiler">
      <b-row>
        <b-col
          sm="12"
          md="3"
        >
          <user-type />
        </b-col>
        <b-col
          sm="12"
          md="3"
        >
          <webform />
        </b-col>
        <b-col
          sm="12"
          md="3"
        >
          <cronjob />
        </b-col>
      </b-row>
    </b-card>
    <user-abilities-card :refresh-data="getUser" />
    <save-button :action-methods="submitForm" />
  </validation-observer>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import NameSurname from '@/views/Core/Users/forms/NameSurname.vue'
import Email from '@/views/Core/Users/forms/Email.vue'
import Brand from '@/views/Core/Users/forms/Brand.vue'
import Department from '@/views/Core/Users/forms/Department.vue'
import Manager from '@/views/Core/Users/forms/Manager.vue'
import UserType from '@/views/Core/Users/forms/UserType.vue'
import Location from '@/views/Core/Users/forms/Location.vue'
import Webform from '@/views/Core/Users/forms/Webform.vue'
import Cronjob from '@/views/Core/Users/forms/Cronjob.vue'
import Birthday from '@/views/Core/Users/forms/Birthday.vue'
import StartDate from '@/views/Core/Users/forms/StartDate.vue'
import Tckn from '@/views/Core/Users/forms/Tckn.vue'
import Phone from '@/views/Core/Users/forms/Phone.vue'
import Internal from '@/views/Core/Users/forms/Internal.vue'
import Password from '@/views/Core/Users/forms/Password.vue'
import SaveButton from '@/views/Core/Users/forms/SaveButton.vue'
import UserSection from '@/views/Core/Users/forms/Section.vue'
import UserAbilitiesCard from '@/views/Core/Users/components/userAbilitiy.vue'

export default {
  name: 'Add',
  components: {
    UserAbilitiesCard,
    ValidationObserver,
    NameSurname,
    Email,
    Brand,
    Department,
    Manager,
    UserType,
    Location,
    Webform,
    Cronjob,
    Birthday,
    StartDate,
    Tckn,
    Phone,
    Internal,
    Password,
    SaveButton,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    UserSection,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
    saveStatus() {
      return this.$store.getters['users/getUserSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.user.submitStatus = false
    },
  },
  created() {
    this.getUser()
    localize(this.locale)
  },
  methods: {
    getUser() {
      this.$store.dispatch('users/userView', this.$route.params.id)
        .then(response => {
          if (response.id) {
            this.$store.dispatch('userAbilities/dataList', {
              select: [
                'com_user_ability.id AS id',
                'com_user_ability.id_com_abilities AS id_com_abilities',
                'com_user_ability.id_com_user AS id_com_user',
              ],
              where: {
                'com_user_ability.id_com_user': response.id,
              },
            })
          }
        })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.user.submitStatus = true
          this.user.editUser = true
          this.$store.dispatch('users/userSave', this.user)
        }
      })
    },
  },
}
</script>
