<template>
  <b-form-group
    label="Bölüm"
    label-for="id_com_user_section"
  >
    <v-select
      id="id_com_user_section"
      v-model="user.id_com_user_section"
      label="title"
      :options="userSections"
      :reduce="userSection => userSection.id"
      placeholder="Seçiniz"
    />
  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'UserSection',
  components: {
    BFormGroup,
    vSelect,

  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
    userSections() {
      return this.$store.getters['userSection/getDataList']
    },
  },
  created() {
    localize(this.locale)
    this.getUserSection()
  },
  methods: {
    getUserSection() {
      this.$store.dispatch('userSection/dataList', {
        select: ['com_user_section.id AS id', 'com_user_section.title AS title'],
      })
    },
  },
}
</script>

<style scoped></style>
