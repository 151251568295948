<template>
  <b-form-group
    label="Kullanıcı Tipi"
    label-for="id_com_user_type"
  >
    <validation-provider
      #default="{ errors }"
      name="Kullanıcı Tipi"
      rules="required"
    >
      <v-select
        id="id_com_user_type"
        v-model="user.id_com_user_type"
        label="title"
        :options="userTypes"
        :reduce="userType => userType.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'UserType',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
    userTypes() {
      return this.$store.getters['userTypes/getUser_types']
    },
  },
  created() {
    localize(this.locale)
    this.getUserTypes()
  },
  methods: {
    getUserTypes() {
      this.$store.dispatch('userTypes/user_typesList', {
        select: ['com_user_type.id AS id', 'com_user_type.title AS title'],
      })
    },
  },
}
</script>

<style scoped></style>
